<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Remitos</h4>
            <div class="small text-muted">Administración de todo el proceso de remitos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col lg="4">
              <Widget :configWidget="configWidget" reference="indicator_total_remits_today" :hasAccess="configWidget.elements.indicatorTotalRemitsToday" />
            </b-col>
            <b-col lg="8">                        
              <Widget :configWidget="configWidget" reference="bar_remits_months" :hasAccess="configWidget.elements.barRemitsMonths" />
            </b-col>                
          </b-row>
        </b-col>
        <b-col lg="3">
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openRemits()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1" v-if="parameters.remitFromOrder">Remitos desde Pedidos</h5>
                <h5 class="mb-1" v-if="parameters.remitFromSale">Remitos desde Ventas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>
              <p class="mb-1">Crear y administrar todos los remitos</p>
            </b-list-group-item>
          </b-list-group>
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPending()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Pendientes</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>
              <p class="mb-1">Listado con todos los productos por remitir</p>
            </b-list-group-item>
          </b-list-group>                    
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
  //import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Param from '@/config/parameters'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.REMITOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.REMITOS,
          profile_id: Profiles.PERSONAL,
          elements: {
            indicatorTotalRemitsToday: true,
            barRemitsMonths: true
          }
        },
        parameters: {
          remitFromOrder: Helper.hasParametersAccess(Param.P26),
          remitFromSale: Helper.hasParametersAccess(Param.P27),
        },         
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalRemitsToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_remits_today')
      this.configWidget.elements.barRemitsMonths = Helper.hasAccessWidget(this.configWidget, 'bar_remits_months')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                      
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openRemits() {
        this.$router.push({ name: 'RemitsStaffRemits' })
      },
      openPending(){
        this.$router.push({ name: 'RemitsStaffPending' })
      },
    }    
  
  }
</script>
<style>

</style>